import React, { useState } from "react";
import "./App.css";

function App() {
  const [showMessage, setShowMessage] = useState(false);

  const handleClick = () => {
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 1000);
  };

  return (
    <div className="App">
      <div className="heart-container" onClick={handleClick}>
        <div className={`heart ${showMessage ? "jump" : ""}`}></div>
        {showMessage && (
          <div className="message">
            <span>You are so so special Tanya!!!!</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
